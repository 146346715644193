import axios from "axios";
import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  SET_AUTH_REDIRECT_PATH,
  SET_STEP,
  UNSET_ERROR,
  INCOMPLETE_PROFILE,
  SEND_MSG,
} from "../types";
import { setUser } from "./profileActions";

import { setActiveComponent } from "./templateActions";
/***************************************************
***** Auth Actions *****
# authStart: 						(set Loading = true, error = null)
# authSuccess: 					(set the token and userId) & (Loading = false, error = null)
# authFail:							(set the error & loading = false)
# logout:								(remove saved session data in local-storage) & (set token, userId = null)
# checkAuthTimeout: 		(set a timer for the session time to auto logout the userAgent)
# setAuthRedirectPath:	(set the path of redirection after authentication)
# authCheckState:				(check for session saved in LocalStorage to update the state) 										
**********************************************/

const WMC_URL = process.env.REACT_APP_API_URL;
const apiUrl = `${process.env.REACT_APP_HDS_API_URL}/auth/v1`;
// const apiUrl = `http://localhost:5500/api/auth/v1`;

const expiresIn = 604800;
export const authStart = () => ({ type: AUTH_START });
export const unsetError = () => ({ type: UNSET_ERROR });
export const sendMsgToUser = (msg) => ({ type: SEND_MSG, msg });
export const setCurrentStep = (step) => {
  localStorage.setItem("step", step);
  return { type: SET_STEP, step };
};

function completeYourProfile(step) {
  localStorage.setItem("step", step);
  return { type: INCOMPLETE_PROFILE, step };
}
function setLocalStorage(
  temp,
  token,
  userId,
  email,
  account_type,
  userName = "",
  form_data = null
) {
  const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
  temp
    ? localStorage.setItem("tempToken", token)
    : localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("email", email);
  localStorage.setItem("userName", userName);
  localStorage.setItem("expirationDate", expirationDate);
  localStorage.setItem("accountType", account_type);
  form_data && localStorage.setItem("form_data", JSON.stringify(form_data));
}

export const authSuccess = (token, userId, userName = "") => {
  return { type: AUTH_SUCCESS, idToken: token, userId, userName };
};

export const authFail = (err) => {
  let error;
  if (err.response) {
    // in case of backend error
    error = err.response.data;
  } else if (err.message) {
    // in case of frontend error
    error = err;
  } else {
    // any other error (e.g: no connection)
    error = { message: "Something wrong happened. Please contact us" };
  }
  //toast.error(error.message ? error.message : error);
  return { type: AUTH_FAIL, error };
};

function clearLocalStorage(exclude) {
  for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i);

    if (exclude.indexOf(key) === -1) {
      localStorage.removeItem(key);
    }
  }
}

export const logout = (removeStep = true) => {
  if (removeStep) {
    localStorage.clear();
  } else {
    clearLocalStorage(["step", "form_data", "tempToken"]);
  }
  //document.cookie = "rememberme=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  return { type: AUTH_LOGOUT };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const setAuthRedirectPath = (path) => {
  return { type: SET_AUTH_REDIRECT_PATH, path: path };
};

export const decodeToken = (token) => {
  return axios({
    method: "get",
    url: `${WMC_URL}/api/auth/verify-token`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.data)
    .catch(() => false);
};

/*
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
*/

export const authCheckState = () => {
  return (dispatch) => {
    // check if there is a non expired token
    const token = localStorage.getItem("token");
    const expirationDate = new Date(localStorage.getItem("expirationDate"));
    if (!token || expirationDate <= new Date()) return dispatch(logout(false));
    // check for remember me feature
    /*const rememberme_token = localStorage.getItem("rememberme");
    const rememberme_cookie = getCookie('rememberme');
    console.log("cookie: ",rememberme_cookie, typeof rememberme_cookie, rememberme_token);
    if(rememberme_token === "false" && rememberme_cookie !== "false") return dispatch(logout(false));*/

    // get data form localStorage and add it to the state
    // const filter = JSON.parse(localStorage.getItem("analytics_filter"));
    const acs = localStorage.getItem("active_components");
    const cps = JSON.parse(localStorage.getItem("captivePortals"));
    // console.log("filter", filter);
    acs && dispatch(setActiveComponent("route", "val", true));

    // log the user in
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    try {
      dispatch(authSuccess(token, userId, userName));
      dispatch(
        checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    } catch (e) {
      console.log(e);
      dispatch(logout(false));
    }
  };
};

export const authCheckProfile = (token) => {
  console.log("Checking profile");
  return async (dispatch) => {
    try {
      const payload = await decodeToken(token);
      if (!payload) throw new Error("Invalid token");
      console.log(`userId: ${payload.userId} && step: ${payload.step}`);
      console.log(
        `customerId: ${payload.customerId} && email: ${payload.email}`
      );
      let step = +payload.step;
      if (step <= 6) {
        setLocalStorage(
          true,
          token,
          payload.userId,
          payload.email,
          payload.account_type,
          payload.userName
        );
        dispatch(completeYourProfile(step + 1));
      } else {
        setLocalStorage(
          false,
          token,
          payload.userId,
          payload.email,
          payload.account_type,
          payload.userName
        );
        dispatch(authSuccess(token, payload.userId, payload.userName));
        dispatch(checkAuthTimeout(expiresIn));
      }
    } catch (err) {
      dispatch(authFail({ message: "Not a valid token" }));
    }
  };
};

function login({ email, password }) {
  return (dispatch) => {
    dispatch(authStart());
    const authData = { email, password };
    let url = `${apiUrl}/login`;
    axios
      .post(url, authData)
      .then((response) => {
        let step = +response.data.step;
        /*if(!rememberme){
        document.cookie = "rememberme=false";
        localStorage.setItem("rememberme", "false");
      }*/
        if (step <= 6) {
          setLocalStorage(
            true,
            response.data.token,
            response.data.userId,
            email,
            response.data.account_type,
            "",
            response.data.form_data
          );
          dispatch(setUser(response.data.userDetails));
          dispatch(completeYourProfile(step + 1));
        } else {
          setLocalStorage(
            false,
            response.data.token,
            response.data.userId,
            email,
            response.data.account_type,
            response.data.userName || ""
          );
          dispatch(
            authSuccess(
              response.data.token,
              response.data.userId,
              response.data.userName
            )
          );
          dispatch(checkAuthTimeout(expiresIn));
        }
      })
      .catch((err) => dispatch(authFail(err)));
  };
}

// function loginWMC({ email, password }) {
//   return (dispatch) => {
//     dispatch(authStart());
//     const authData = { email, password };
//     let url = `${process.env.REACT_APP_API_URL}/auth/wmc`;
//     axios
//       .post(url, authData)
//       .then((response) => {
//         let step = +response.data.step;
//         /*if(!rememberme){
//         document.cookie = "rememberme=false";
//         localStorage.setItem("rememberme", "false");
//       }*/
//         if (step <= 6) {
//           setLocalStorage(
//             true,
//             response.data.token,
//             response.data.userId,
//             email,
//             response.data.account_type,
//             ""
//           );
//           dispatch(setUser(response.data.userDetails));
//           dispatch(completeYourProfile(step + 1));
//         } else {
//           setLocalStorage(
//             false,
//             response.data.token,
//             response.data.userId,
//             email,
//             response.data.account_type,
//             response.data.userName || ""
//           );
//           dispatch(
//             authSuccess(
//               response.data.token,
//               response.data.userId,
//               response.data.userName
//             )
//           );
//           dispatch(checkAuthTimeout(expiresIn));
//         }
//       })
//       .catch((err) => dispatch(authFail(err)));
//   };
// }

function createAnAccount({ email, password1, password2 }) {
  return (dispatch) => {
    dispatch(authStart());
    if (password1 !== password2) {
      return dispatch(authFail({ message: "These passwords does not match" }));
    }
    const authData = { email, password: password1 };
    let url = `${apiUrl}/sign-up`;
    axios
      .post(url, authData)
      .then((response) => {
        console.log(response);
        setLocalStorage(
          true,
          response.data.token,
          response.data.userId,
          email,
          response.data.account_type,
          ""
        );
        localStorage.setItem("email", email);
        dispatch(setUser(response.data.userId, email));
        dispatch(completeYourProfile(1));
      })
      .catch((err) => dispatch(authFail(err)));
  };
}

function forgotPassword({ email }) {
  return (dispatch) => {
    dispatch(authStart());
    const authData = { email };
    let url = `${apiUrl}/forgot-password`;
    console.log("response");
    axios
      .post(url, authData)
      .then((response) => {
        console.log("response");
        dispatch(
          sendMsgToUser(`We have sent you an email. Go to your email 
				and Click on reset password`)
        );
      })
      .catch((err) => dispatch(authFail(err)));
  };
}

function changePassword({ password1, password2 }) {
  return (dispatch) => {
    dispatch(authStart());
    if (password1 !== password2) {
      return dispatch(authFail({ message: "These passwords does not match" }));
    }
    const userId = +localStorage.getItem("userId");
    if (!userId)
      return dispatch(
        authFail({ message: "You can not change your password right now" })
      );

    const authData = { password: password1, userId: userId };
    let url = `${apiUrl}/change-password`;
    axios
      .post(url, authData)
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          dispatch(
            sendMsgToUser(
              "You have change your password successfully. Try to login now"
            )
          );
        }
      })
      .catch((err) => dispatch(authFail(err)));
  };
}

export const handleAuthentication = (authType, formData) => {
  switch (authType) {
    case "login":
      return login(formData);
    case "loginWMC":
      return login(formData);
    case "signup":
      return createAnAccount(formData);
    case "forgotPassword":
      return forgotPassword(formData);
    case "changePassword":
      return changePassword(formData);
    default:
      return false;
  }
};

export const authCheckResetToken = (token) => {
  console.log("Checking reset token");
  return async (dispatch) => {
    dispatch(authStart());
    try {
      const payload = await decodeToken(token);
      if (!payload) throw new Error("Not a valid token");
      let change = payload.changePassword;
      console.log("change = ", change);
      console.log("typeof change : ", typeof change);
      if (change) {
        setLocalStorage(true, token, payload.userId, payload.email);
        dispatch(sendMsgToUser(`You can change Your password Now`));
      } else {
        dispatch(authFail({ message: "Not a valid token" }));
      }
    } catch (err) {
      dispatch(authFail({ message: "Not a valid token" }));
    }
  };
};
