import axios from "axios";
import {
  START_STEP,
  FAIL_STEP,
  SET_USER_DETAILS,
  SEND_MSG,
  SUCCESS_STEP,
  CLEAR_ERROR,
} from "../types";
import { setCurrentStep, authSuccess } from "./authActions";

const apiUrl = `${process.env.REACT_APP_HDS_API_URL}/auth/v1`;
// const apiUrl = `http://localhost:5500/api/auth/v1`;

export const startStep = () => {
  return { type: START_STEP };
};
export const clearError = () => {
  return { type: CLEAR_ERROR };
};
export const successStep = () => {
  return { type: SUCCESS_STEP };
};
export const setUser = (userDetails) => {
  return { type: SET_USER_DETAILS, userDetails };
};
export const failStep = (error) => {
  //toast.error(error.message ? error.message : error);
  return { type: FAIL_STEP, error };
};
export const sendMsgToUser = (msg) => {
  return { type: SEND_MSG, msg };
};

function setLocalStorage(token, userId, temp = false) {
  const expirationDate = new Date(new Date().getTime() + 86400 * 1000);
  temp
    ? localStorage.setItem("token", token)
    : localStorage.setItem("tempToken", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("expirationDate", expirationDate);
}

function updateFormDataStorage(form_data) {
  let storageForm = localStorage.getItem("form_data");
  let data = { ...JSON.parse(storageForm), ...form_data };
  form_data && localStorage.setItem("form_data", JSON.stringify(data));
}

function isFormTheSame(child) {
  let storageForm = localStorage.getItem("form_data");
  if (!storageForm) return false;
  let parent = JSON.parse(storageForm);
  const keys = Object.keys(child);
  for (let key of keys) {
    if (child[key] !== parent[key]) {
      return false;
    }
  }
  return true;
}

function verifyEmail(formData, formStep) {
  return (dispatch) => {
    console.log("success step: ", formStep);
    dispatch(setCurrentStep(formStep + 1));
    dispatch(successStep());
  };
}

function addPersonalInfo(formData, formStep) {
  return (dispatch) => {
    dispatch(startStep());
    console.log(formData);
    const userId = localStorage.getItem("userId");
    let url = `${apiUrl}/personal-info`;
    if (
      !formData["First_Name"] ||
      !formData["Last_Name"] ||
      !formData["Date_Of_Birth"] ||
      !formData["Address_Line_1"] ||
      !formData["City"] ||
      !formData["Country"]
      //!formData['zip']
    ) {
      return dispatch(failStep({ message: "Please fill all required fields" }));
    }
    if (isFormTheSame(formData)) {
      dispatch(setCurrentStep(formStep + 1));
      return dispatch(successStep());
    }
    axios
      .post(
        url,
        { formData, userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tempToken")}`,
          },
        }
      )
      .then((response) => {
        dispatch(setCurrentStep(formStep + 1));
        updateFormDataStorage({ ...formData });
        dispatch(successStep());
      })
      .catch((err) => {
        let error = err.response
          ? err.response.data
          : { message: "Something wrong happened" };
        dispatch(failStep(error));
      });
  };
}

function addCompanyInfo(formdata, formStep, skip) {
  return (dispatch) => {
    if (skip) {
      dispatch(setCurrentStep(formStep + 1));
      return dispatch(successStep());
    }
    dispatch(startStep());
    console.log(formdata);
    // validate form
    let {
      companyName,
      companyAddress,
      taxDocFile,
      taxDocName,
      commercialDocName,
      commercialDocFile,
      image,
    } = formdata;

    if (
      !companyName
      // !companyAddress ||
      // !taxDocFile ||
      // !commercialDocFile ||
      // !image
    ) {
      return dispatch(failStep({ message: "Complete the required fields" }));
    }

    const formData = new FormData();
    //formData.append('attachments[]', commercialDocFile);
    //formData.append('attachments[]', taxDocFile);
    formData.append("profile", image);
    formData.append("commercialDocFile", commercialDocFile);
    formData.append("taxDocFile", taxDocFile);
    formData.append("companyName", companyName);
    //formData.append('description', companyAddress);
    formData.append("officeAddress", companyAddress);
    formData.append("commercialDocName", commercialDocName);
    formData.append("taxDocName", taxDocName);
    //formData.append('custom_fields[cf_department]', industry);
    //authData.append('website', website ? website : '');
    //authData.append('userId', localStorage.getItem('userId'));

    let url = `${apiUrl}/company-info`;
    axios
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("tempToken")}`,
        },
      })
      .then((response) => {
        console.log(response.status);
        dispatch(setCurrentStep(formStep + 1));
        dispatch(successStep());
      })
      .catch((err) => {
        console.log(err);
        let error = err.response
          ? err.response.data
          : { message: "Something wrong happened" };
        dispatch(failStep(error));
      });
  };
}

function validatePhoneNumber(number) {
  //Validating Egyptian numbers.
  if (number[0] + number[1] === "20") {
    if (number[2] === "0") number.splice(2, 1);
    let phonePattern = new RegExp(/^([1]{1})[0-2,5]{1}\d{8}$/g);
    let valid = phonePattern.test(number.join("").substring(2));
    if (!valid) return false;
  }
  return number.join("");
}

function sendSMS(formData, formStep) {
  return (dispatch) => {
    dispatch(startStep());

    let number = formData.split("");
    if (formData.length > 8) {
      number = validatePhoneNumber(number);
      if (!number)
        return dispatch(failStep({ message: "This number is not valid" }));
    } else {
      return dispatch(failStep({ message: "Phone number is required" }));
    }
    axios({
      url: `${apiUrl}/add-phone`,
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("tempToken")}` },
      data: { phone: number },
    })
      .then((result) => {
        dispatch(setCurrentStep(formStep + 2));
        updateFormDataStorage({ Phone: number });
        dispatch(successStep());
      })
      .catch((err) => {
        let error = err.response
          ? err.response.data
          : { message: "Something wrong happened" };
        dispatch(failStep(error));
      });
  };
}

function verifySMS(formData, formStep) {
  return (dispatch) => {
    dispatch(startStep());
    console.log(formData);
    if (
      !formData.digit1 ||
      !formData.digit2 ||
      !formData.digit3 ||
      !formData.digit4
    ) {
      dispatch(failStep({ message: "Verification code consists of 4 digits" }));
      return;
    }
    let code =
      formData.digit1 + formData.digit2 + formData.digit3 + formData.digit4;
    console.log(code);
    axios({
      url: `${apiUrl}/verifyphone`,
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("tempToken")}` },
      data: { code },
    })
      .then((result) => {
        if (result.data.status === "success") {
          dispatch(setCurrentStep(formStep + 1));
          dispatch(successStep());
        } else {
          dispatch(failStep({ message: "Wrong code, try again." }));
        }
      })
      .catch((err) => {
        let error = err.response
          ? err.response.data
          : { message: "Something wrong happened" };
        dispatch(failStep(error));
      });
  };
}

function skipPayment() {
  return (dispatch) => {
    axios({
      url: `${apiUrl}/finish-signup`,
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("tempToken")}` },
    })
      .then((response) => {
        setLocalStorage(response.data.token, response.data.userId, true);
        return dispatch(
          authSuccess(
            response.data.token,
            response.data.userId,
            response.data.userName
          )
        );
      })
      .catch((err) => {
        let error = err.response
          ? err.response.data
          : { message: "Something wrong happened" };
        dispatch(failStep(error));
      });
  };
}

// function addPaymentMethod(formData, formStep, skip) {
//   return (dispatch) => {
//     console.log("skip = ", skip);
//     if (skip) {
//       return dispatch(skipPayment());
//     }
//     console.log(formData);
//     axios({
//       url: `${apiUrl}/addcard`,
//       method: "post",
//       headers: { Authorization: `Bearer ${localStorage.getItem("tempToken")}` },
//       data: formData,
//     }).then((result) => {
//       console.log(result);
//     });

//     return false;
//   };
// }

export const completeProfile = (formData, formStep, skip = false) => {
  console.log(formStep);
  switch (formStep) {
    case 1:
      return verifyEmail(formData, formStep);
    case 2:
      return addPersonalInfo(formData, formStep);
    case 3:
      return addCompanyInfo(formData, formStep, skip);
    case 4:
      return sendSMS(formData, formStep);
    case 5:
      return verifySMS(formData, formStep);
    case 6:
      return skipPayment();
    default:
      return false;
  }
};

export const resendEmail = (userEmail) => {
  return (dispatch) => {
    dispatch(startStep());
    if (!userEmail) {
      return dispatch(
        failStep({
          message: "Something wrong happened, please Login and try again",
        })
      );
    }
    console.log(userEmail);
    //call the backend to resend the mail
    const authData = { email: userEmail };
    let url = `${apiUrl}/resend-email`;
    axios
      .post(url, authData)
      .then((response) => {
        // Successfully sent
        dispatch(sendMsgToUser("We have resent the email successfully"));
        dispatch(successStep());
      })
      .catch((err) => {
        let error = err.response
          ? err.response.data
          : { message: "Something wrong happened" };
        dispatch(failStep(error));
      });
  };
};
