import {
	START_STEP,
	SUCCESS_STEP,
	FAIL_STEP,
	SET_USER_DETAILS,
	SEND_MSG,
	CLEAR_ERROR
} from '../types';

const initialState = {
	error: null,
	loading: false,
	nextStep: false,
	currentStep: 1,
	successMsg: null,
	userDetails: {}
};

const reducer = ( state = initialState, action ) => {
	switch ( action.type ) { 
		case START_STEP: 
			return {
				...state,
				loading: true,
				error: null
			}		
		case SUCCESS_STEP: 
			return {
				...state,
				loading: false,
				error: null
			}	
		case FAIL_STEP:
			return {
				...state, 
				error: action.error,
				loading: false
			}
		case SET_USER_DETAILS:
			return {
				...state, 
				userDetails: {...state.userDetails, ...action.userDetails}
			}
		case SEND_MSG:
			return {
				...state, 
				successMsg: action.msg 
			}		
		case CLEAR_ERROR:
			return {
				...state, 
				error: null,
				loading: false
			}
		default:
			return state;
	}
};

export default reducer;