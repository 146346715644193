import {
  CAMPAIGNS_START,
  CAMPAIGNS_FINISH,
  CAMPAGINS_ERROR,
  SET_CAMPAIGNS,
  SET_CAMPAIGNS_COMPONENT,
  SET_CAMPAIGNS_EDIT,
  // ADD_CAMPAIGN,
  // REMOVE_CAMPAIGN,
  SET_CAMPAIGN_FORM,
  ADD_CAMPAIGN_ASSET,
  TOGGLE_POPUP_ASSET,
  TOGGLE_POPUP_CAMPAIGN,
  RESET_CAMPAIGN_FORM,
  SET_CAMPAIGNS_INVENTORIES,
  SET_CAMPAIGNS_ASSETS,
  PREPARE_CAMPAIGN_EDIT,
  DELETE_CAMPAIGN_ASSET,
  PREPARE_CAMPAIGN_ASSET,
  RESET_ASSET_FORM,
  SET_GROUPS,
  SET_AP_GROUPS,
  TOGGLE_INVENTORY_APP_ENABLED,
} from "../types";
import axios from "axios";
import { toast } from "react-toastify";

function start() {
  return { type: CAMPAIGNS_START };
}

function finish() {
  return { type: CAMPAIGNS_FINISH };
}

function setError(err) {
  return { type: CAMPAGINS_ERROR, payload: err };
}
export function setCampaigns(data) {
  return { type: SET_CAMPAIGNS, payload: data };
}
function setAssets(data) {
  return { type: SET_CAMPAIGNS_ASSETS, payload: data };
}
function setInventories(data) {
  return { type: SET_CAMPAIGNS_INVENTORIES, payload: data };
}

function prepareCampaignEdit(inventories, assets, tableAssets, camp) {
  return {
    type: PREPARE_CAMPAIGN_EDIT,
    inventories,
    assets,
    tableAssets,
    startDate: camp.startDate,
    endDate: camp.endDate,
    name: camp.name,
    id: camp.id,
  };
}
export function setCurrentComponent(comp) {
  return { type: SET_CAMPAIGNS_COMPONENT, payload: comp };
}
export function togglePopUpAsset(payload, application, inventoryId) {
  return { type: TOGGLE_POPUP_ASSET, payload, application, inventoryId };
}
export function togglePopUpCampaign(val) {
  return { type: TOGGLE_POPUP_CAMPAIGN, payload: val };
}
export function resetCampaignForm() {
  return { type: RESET_CAMPAIGN_FORM };
}
export function setAPGroups(groups) {
  return { type: SET_AP_GROUPS, payload: groups };
}
export function resetAssetForm() {
  return { type: RESET_ASSET_FORM };
}
export function handleCampaignForm(name, value) {
  return (dispatch) => {
    dispatch({ type: SET_CAMPAIGN_FORM, name, value });
  };
}
export function prepareCampaignAsset(id) {
  return { type: PREPARE_CAMPAIGN_ASSET, payload: id };
}
export function setEditID(id) {
  return (dispatch) => {
    dispatch({ type: SET_CAMPAIGNS_EDIT, payload: id });
  };
}

/**
 * this function is called when edit or add asset button is clicked
 */
export function addCampaignAsset() {
  return (dispatch, getState) => {
    const {
      campaigns: { campaignForm },
    } = getState();
    if (
      !campaignForm.os ||
      !campaignForm.clicks ||
      (campaignForm.application === "hotspot" &&
        (!campaignForm.connect || !campaignForm.skip)) ||
      (campaignForm.application === "heplex" && !campaignForm.frequency) ||
      !campaignForm.view ||
      !campaignForm.duration ||
      !campaignForm.asset
    ) {
      toast.warn("All fields are required");
    } else {
      dispatch({ type: ADD_CAMPAIGN_ASSET });
    }
  };
}

export const getCampaigns = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/campaign/all`,
    })
      .then((result) => {
        console.log(result.data.message);
        let data = result.data.message;
        dispatch(setCampaigns(data));
        dispatch(finish());
      })
      .catch((err) => {
        dispatch(setError(err));
      });
  };
};
export function setSelectedGroups(groups) {
  return { type: SET_GROUPS, payload: groups };
}
export const handleGroups = (e, gr) => {
  return (dispatch, getState) => {
    console.log(gr);
    let groups = getState().campaigns.campaignForm.selectedGroups;
    if (e.target.checked === true) {
      groups = [...groups, gr.ID];
      dispatch(setSelectedGroups(groups));
    } else {
      let index = groups.findIndex((x) => x === gr.ID);
      groups.splice(index, 1);
      dispatch(setSelectedGroups(groups));
    }
  };
};

/**
 * this function is called when save button is clicked
 * if it is called from new campaign component, it adds the campaign
 * if it is called from edit campaign component, it updates the campaign
 */
export const saveCampaign = () => {
  return (dispatch, getState) => {
    const { campaigns } = getState();
    const campaign = {
      id: campaigns.editItem,
      name: campaigns.campaignForm.name,
      inventories: campaigns.campaignForm.inventories,
      startDate: campaigns.campaignForm.startDate.toLocaleDateString("en-US"),
      endDate: campaigns.campaignForm.endDate.toLocaleDateString("en-US"),
      assets: campaigns.campaignAssets,
    };
    const url = `${process.env.REACT_APP_API_URL}/campaign/${
      campaigns.component === "new" ? "add" : "edit"
    }`;

    axios({
      method: "post",
      headers: { Authorization: `Bearer: ${localStorage.getItem("token")}` },
      url,
      data: campaign,
    })
      .then((result) => {
        if (result.data.message === "success") {
          campaigns.component === "new"
            ? toast.success("Added Successfully")
            : toast.success("Updated successfully");
          dispatch(togglePopUpCampaign(false));
          dispatch(setCurrentComponent("overview"));
          dispatch(resetCampaignForm());
        }
      })
      .catch((err) => {
        console.log(err);
        const errorMessage =
          err.response?.data?.message || err.message || "Something went wrong";
        toast.error(errorMessage);
      });
  };
};

export const getAssets = () => {
  return (dispatch, getState) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/assetCamp`,
    })
      .then((result) => {
        let da = result.data.data;
        dispatch(setAssets(da));
      })
      .then(() => {
        axios({
          method: "get",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          url: `${process.env.REACT_APP_API_URL}/myinventories/campInventories`,
        }).then((result) => {
          let inventories = result.data.data;

          // for each inventory, get the apps
          let calls = [];
          inventories.map((inv) => {
            calls.push(
              axios({
                method: "get",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                url: `${process.env.REACT_APP_API_URL}/myinventories/apps/${inv.value}`,
              })
            );
          });

          Promise.all(calls)
            .then((res) => {
              res.map((r, i) => {
                let apps = r.data.data;
                apps = apps.map((app) => {
                  return {
                    label: app === "hl" ? "heplex" : "hotspot",
                    enabled: true, // this should be set when we get the campaign details
                  };
                });
                inventories[i].apps = apps;
              });
              dispatch(setInventories(inventories));
            })
            .catch((err) => console.log(err));
        });
      });
  };
};

/**
 * this function is called when edit button is clicked
 * it gets the campaign details from db and dispatches it to the reducer
 * @param {*} camp
 */
export const editCampaign = (camp) => {
  console.log("edit");
  return (dispatch, getState) => {
    let inventories = [];
    let assets = [];
    let tableAssets = [];
    let osS = [];
    // let selectedGroups = [];
    axios({
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/campaign/getCampInfo`,
      data: { id: camp.id },
    })
      .then((result) => {
        if (result.data.inventories?.length) {
          result.data.inventories.map((inv) => {
            getState().campaigns.inventories.map((campInv) => {
              if (inv.tenantId === campInv.value) {
                inventories.push({ ...campInv, id: inv.id });
              }
            });
          });
        }
        if (result.data.assets?.length) {
          result.data.assets.map((ast) => {
            getState().campaigns.assets.map((campAst) => {
              if (ast.assetId === campAst.value) {
                ast.userProps.osNames.map((os) => {
                  console.log(os);
                  getState().campaigns.osOptions.map((osop) => {
                    if (osop.value === os) osS.push(osop);
                  });
                });
                assets.push({
                  id: ast.id,
                  inventoryId: ast.inventoryId,
                  active: Boolean(ast.active),
                  asset: campAst,
                  clicks: ast.targetClicks,
                  connect: ast.connectUrl,
                  skip: ast.skipUrl,
                  duration: ast.counterDuration,
                  frequency: ast.frequency,
                  view: ast.targetViews,
                  application: ast.application,
                  os: osS,
                  // selectedGroups,
                  op: getState().campaigns.opOptions.filter(
                    (op) => op.value === ast.targetOperator
                  )[0],
                });
                osS = [];
                // selectedGroups = [];
                tableAssets.push({
                  id: ast.id,
                  inventoryId: ast.inventoryId,
                  active: Boolean(ast.active),
                  asset: campAst.label,
                  clicks: ast.targetClicks,
                  connect: ast.connectUrl,
                  skip: ast.skipUrl,
                  duration: ast.counterDuration,
                  frequency: ast.frequency,
                  view: ast.targetViews,
                  os: ast.userProps.osNames.join(","),
                  op: ast.targetOperator,
                  application: ast.application,
                });
              }
            });
          });

          inventories.map((inv) => {
            let heplexEnabled = assets.some(
              (asset) =>
                asset.inventoryId === inv.value &&
                asset.application === "heplex" &&
                asset.active
            );

            let hotspotEnabled = assets.some(
              (asset) =>
                asset.inventoryId === inv.value &&
                asset.application === "hotspot" &&
                asset.active
            );

            inv.apps.map((app) => {
              if (app.label === "heplex") {
                app.enabled = heplexEnabled;
              } else {
                app.enabled = hotspotEnabled;
              }
            });
          });
        }
        console.log({ inventories, assets, tableAssets, camp });
        dispatch(prepareCampaignEdit(inventories, assets, tableAssets, camp));
      })
      .catch((err) => console.log(err));
  };
};

export const deleteCampaignAsset = (id) => {
  return (dispatch) => {
    axios({
      method: "delete",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/campaign/campaign-asset/delete/${id}`,
    })
      .then((result) => {
        toast.success("asset removed Successfully");
        dispatch({ type: DELETE_CAMPAIGN_ASSET, payload: id });
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
};

export const toggleInventoryAppEnabled = (inventoryId, appIdx, app) => {
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_INVENTORY_APP_ENABLED, inventoryId, appIdx, app });
  };
};

// export const getAPGroups = () => {
//   return (dispatch, getState) => {
//     let ids = [];
//     let inventories = getState().campaigns.campaignForm.inventories;
//     inventories && inventories.map((c) => ids.push(c.value));
//     console.log(ids);
//     axios({
//       method: "post",
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       url: `${process.env.REACT_APP_API_URL}/campaign/groups`,
//       data: { ids },
//     })
//       .then((result) => {
//         console.log(result.data);
//         dispatch(setAPGroups(result.data.groups));
//         // dispatch(setAPGroups(result.data));
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };
