import {
  INVENTORY_START,
  INVENTORY_FINISH,
  INVENTORY_ERROR,
  SET_INVENTORIES,
  SET_COMPONENT,
  SET_PURCHASED_COMPONENT,
  SET_PREVIEW_ID,
  SET_PURCHASED_PREVIEW,
  ADD_INVENTORY,
  SET_PURCHASED_INVENTORIES,
  REMOVE_INVENTORY,
} from "../types";
const initialState = {
  isLoading: false,
  error: false,
  component: "",
  purchasedComponent: "",
  inventories: [],
  previewItem: "",
  purchasedPreviewItem: "",
  purchasedInventories: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case INVENTORY_FINISH:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case INVENTORY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SET_INVENTORIES:
      return {
        ...state,
        inventories: action.payload,
      };
    case SET_PURCHASED_INVENTORIES:
      return {
        ...state,
        purchasedInventories: action.payload,
      };
    case SET_COMPONENT:
      return {
        ...state,
        component: action.payload,
      };
    case SET_PURCHASED_COMPONENT:
      return {
        ...state,
        purchasedComponent: action.payload,
      };
    case SET_PREVIEW_ID:
      return {
        ...state,
        previewItem: action.payload,
        component: "preview",
      };
    case SET_PURCHASED_PREVIEW:
      return {
        ...state,
        purchasedPreviewItem: action.payload,
        purchasedComponent: "preview",
      };
    case ADD_INVENTORY:
      return {
        ...state,
        purchasedInventories: [...state.purchasedInventories, action.payload],
      };
    case REMOVE_INVENTORY:
      return {
        ...state,
        purchasedInventories: state.purchasedInventories.filter(
          (e) => e.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default reducer;
