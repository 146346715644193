import {
  CAMPAIGNS_START,
  CAMPAIGNS_FINISH,
  CAMPAGINS_ERROR,
  SET_CAMPAIGNS,
  SET_CAMPAIGNS_COMPONENT,
  SET_CAMPAIGNS_EDIT,
  ADD_CAMPAIGN,
  REMOVE_CAMPAIGN,
  SET_CAMPAIGN_FORM,
  SET_CAMPAIGNS_ASSETS,
  SET_CAMPAIGNS_INVENTORIES,
  ADD_CAMPAIGN_ASSET,
  TOGGLE_POPUP_ASSET,
  RESET_CAMPAIGN_FORM,
  TOGGLE_POPUP_CAMPAIGN,
  PREPARE_CAMPAIGN_EDIT,
  PREPARE_CAMPAIGN_ASSET,
  DELETE_CAMPAIGN_ASSET,
  RESET_ASSET_FORM,
  TOGGLE_INVENTORY_APP_ENABLED,
  // SET_GROUPS,
  // SET_AP_GROUPS,
} from "../types";

//Starting with default values
const initialState = {
  isLoading: false,
  error: false,
  component: "",
  campaigns: [],
  editItem: "",
  assetId: "",
  campaignForm: {
    startDate: new Date(),
    endDate: new Date(),
    name: "",
    op: {
      label: "OR",
      value: "OR",
    },
    // selectedGroups: [],
  },
  osOptions: [
    { label: "ios", value: "ios" },
    { label: "macOS", value: "macOS" },
    { label: "android", value: "android" },
    { label: "windows", value: "windows" },
    { label: "linux", value: "linux" },
  ],
  opOptions: [
    { label: "OR", value: "OR" },
    { label: "AND", value: "AND" },
  ],
  tableAssets: [],
  popUpAsset: false,
  popUpCampaign: false,
  campaignAssets: [],
  inventories: [],
  assets: [],
  apgroups: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGNS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CAMPAIGNS_FINISH:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case CAMPAGINS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
      };
    case SET_CAMPAIGNS_COMPONENT:
      return {
        ...state,
        component: action.payload,
      };
    case SET_CAMPAIGNS_EDIT:
      return {
        ...state,
        editItem: action.payload,
        component: "edit",
      };
    case SET_CAMPAIGN_FORM:
      return {
        ...state,
        campaignForm: { ...state.campaignForm, [action.name]: action.value },
      };
    case TOGGLE_INVENTORY_APP_ENABLED:
      return {
        ...state,
        campaignAssets: state.campaignAssets.map((asset) =>
          asset.inventoryId === action.inventoryId &&
          asset.application === action.app.label
            ? {
                ...asset,
                active: !asset.active,
              }
            : asset
        ),
        campaignForm: {
          ...state.campaignForm,
          inventories: state.campaignForm.inventories.map((inv) =>
            inv.value === action.inventoryId
              ? {
                  ...inv,
                  apps: inv.apps.map((app, j) =>
                    j === action.appIdx
                      ? { ...app, enabled: !app.enabled }
                      : app
                  ),
                }
              : inv
          ),
        },
      };
    case ADD_CAMPAIGN:
      return {
        ...state,
        purchasedInventories: [...state.purchasedInventories, action.payload],
      };
    case ADD_CAMPAIGN_ASSET:
      console.log({ state });
      const isNewAsset = state.assetId === "";
      const campaignAssetId = isNewAsset ? "" : state.assetId;
      const actionAsset = {
        ...state.campaignForm,
        id: campaignAssetId,
        os: state.campaignForm.os,
      };
      const actionTableAsset = {
        ...state.campaignForm,
        asset: state.campaignForm.asset.label,
        os: state.campaignForm.os.map((o) => o.label).toString(),
        op: state.campaignForm.op.label,
      };

      return {
        ...state,
        campaignAssets: isNewAsset
          ? [...state.campaignAssets, actionAsset]
          : state.campaignAssets.map((e) =>
              e.id === state.assetId ? actionAsset : e
            ),
        tableAssets: isNewAsset
          ? [...state.tableAssets, actionTableAsset]
          : state.tableAssets.map((e) =>
              e.id === state.assetId ? actionTableAsset : e
            ),
        // resetting form
        campaignForm: {
          ...state.campaignForm,
          asset: "",
          duration: "",
          frequency: "",
          skip: "",
          connect: "",
          op: initialState.campaignForm.op,
          os: "",
          view: "",
          clicks: "",
          // selectedGroups: [],
        },
        // hide popup
        popUpAsset: false,
        // reset asset id
        assetId: "",
      };
    case TOGGLE_POPUP_ASSET:
      return {
        ...state,
        popUpAsset: action.payload,
        campaignForm: {
          ...state.campaignForm,
          application: action.application,
          inventoryId: action.inventoryId,
        },
      };
    case RESET_CAMPAIGN_FORM:
      return {
        ...state,
        campaignForm: initialState.campaignForm,
        campaignAssets: [],
        tableAssets: [],
        assetId: "",
      };
    case RESET_ASSET_FORM:
      return {
        ...state,
        campaignForm: {
          ...state.campaignForm,
          asset: "",
          duration: "",
          frequency: "",
          skip: "",
          connect: "",
          op: initialState.campaignForm.op,
          os: [],
          view: "",
          clicks: "",
          // selectedGroups: [],
        },
        assetId: "",
      };
    case TOGGLE_POPUP_CAMPAIGN:
      return {
        ...state,
        popUpCampaign: action.payload,
      };
    case SET_CAMPAIGNS_INVENTORIES:
      return {
        ...state,
        inventories: action.payload,
      };
    case SET_CAMPAIGNS_ASSETS:
      return {
        ...state,
        assets: action.payload,
      };
    case DELETE_CAMPAIGN_ASSET:
      // let assts = state.campaignAssets;
      // let tabassts = state.tableAssets;
      // assts.splice(action.payload, 1);

      // tabassts.splice(action.payload, 1);

      return {
        ...state,
        tableAssets: state.tableAssets.filter((e) => e.id !== action.payload),
        campaignAssets: state.campaignAssets.filter(
          (e) => e.id !== action.payload
        ),
      };
    case PREPARE_CAMPAIGN_EDIT:
      console.log({ action });
      return {
        ...state,
        campaignForm: {
          ...state.campaignForm,
          name: action.name,
          startDate: new Date(action.startDate),
          endDate: new Date(action.endDate),
          inventories: action.inventories,
        },
        campaignAssets: action.assets,
        component: "edit",
        tableAssets: action.tableAssets,
        editItem: action.id,
      };
    case PREPARE_CAMPAIGN_ASSET:
      const selectedCampaign = state.campaignAssets.find(
        (e) => e.id === action.payload
      );
      return {
        ...state,
        assetId: action.payload,
        campaignForm: {
          ...state.campaignForm,
          inventoryId: selectedCampaign.inventoryId,
          asset: selectedCampaign.asset,
          connect: selectedCampaign.connect,
          skip: selectedCampaign.skip,
          clicks: selectedCampaign.clicks,
          view: selectedCampaign.view,
          duration: selectedCampaign.duration,
          frequency: selectedCampaign.frequency,
          os: selectedCampaign.os,
          op: selectedCampaign.op,
          application: selectedCampaign.application,
          // selectedGroups: selectedCampaign.selectedGroups,
        },
        popUpAsset: true,
      };
    case REMOVE_CAMPAIGN:
      state.purchasedInventories.splice(
        state.purchasedInventories.findIndex((e) => e.id === action.payload),
        1
      );
    // case SET_GROUPS:
    //   return {
    //     ...state,
    //     campaignForm: {
    //       ...state.campaignForm,
    //       selectedGroups: action.payload,
    //     },
    //   };
    // case SET_AP_GROUPS:
    //   let groups = [];
    //   action.payload.map((i) => groups.push(i.ID));
    //   return {
    //     ...state,
    //     apgroups: action.payload,
    //     campaignForm: {
    //       ...state.campaignForm,
    //       selectedGroups: groups,
    //     },
    //   };
    default:
      return state;
  }
};

export default reducer;
