// template
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const SET_ACTIVE_COMPONENT = "SET_ACTIVE_COMPONENT";
export const USER_EDITING = "USER_EDITING";
// auth
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const SET_STEP = "SET_STEP";
export const UNSET_ERROR = "UNSET_ERROR";
export const INCOMPLETE_PROFILE = "INCOMPLETE_PROFILE";
// profile
export const START_STEP = "START_STEP";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const FAIL_STEP = "FAIL_STEP";
export const SEND_MSG = "SEND_MSG";
export const SUCCESS_STEP = "SUCCESS_STEP";
export const CLEAR_ERROR = "CLEAR_ERROR";

//Campaigns
export const CAMPAIGNS_START = "CAMPAIGNS_START";
export const CAMPAIGNS_FINISH = "CAMPAIGNS_FINISH";
export const CAMPAGINS_ERROR = "CAMPAIGNS_ERROR";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const SET_CAMPAIGNS_COMPONENT = "SET_CAMPAIGNS_COMPONENT";
export const SET_CAMPAIGNS_EDIT = "SET_CAMPAIGNS_EDIT";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const ADD_CAMPAIGN_ASSET = "ADD_CAMPAIGN_ASSET";
export const REMOVE_CAMPAIGN = "REMOVE_CAMPAIGN";
export const SET_CAMPAIGN_FORM = "SET_CAMPAIGN_FORM";
export const TOGGLE_POPUP_ASSET = "TOGGLE_POPUP_ASSET";
export const RESET_CAMPAIGN_FORM = "RESET_CAMPAIGN_FORM";
export const RESET_ASSET_FORM = "RESET_ASSET_FORM";
export const TOGGLE_POPUP_CAMPAIGN = "TOGGLE_POPUP_CAMPAIGN";
export const SET_CAMPAIGNS_ASSETS = "SET_CAMPAIGN_ASSETS";
export const SET_CAMPAIGNS_INVENTORIES = "SET_CAMPAIGN_INVENTORIES";
export const PREPARE_CAMPAIGN_EDIT = "PREPARE_CAMPAIGN_EDIT";
export const PREPARE_CAMPAIGN_ASSET = "PREPARE_CAMPAIGN_ASSET";
export const DELETE_CAMPAIGN_ASSET = "DELETE_CAMPAIGN_ASSET";
export const SET_GROUPS = "SET_GROUPS";
export const SET_AP_GROUPS = "SET_AP_GROUPS";
export const TOGGLE_INVENTORY_APP_ENABLED = "TOGGLE_INVENTORY_APP_ENABLED";

//Inventories
export const INVENTORY_START = "INVENTORY_START";
export const INVENTORY_FINISH = "INVENTORY_FINISH";
export const INVENTORY_ERROR = "INVENTORY_ERROR";
export const SET_INVENTORIES = "SET_INVENTORIES";
export const SET_COMPONENT = "SET_COMPONENT";
export const SET_PREVIEW_ID = "SET_PREVIEW_ID";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const REMOVE_INVENTORY = "REMOVE_INVENTORY";
export const SET_PURCHASED_INVENTORIES = "SET_PURCHASED_INVENTORIES";
export const SET_PURCHASED_PREVIEW = "SET_PURCHASED_PREVIEW";
export const SET_PURCHASED_COMPONENT = "SET_PURCHASED_COMPONENT";

export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const DASHBOARD_FINISH = "DASHBOARD_FINISH";
export const DASHBOARD_SET_CAMPAIGNS = "DASHBOARD_SET_CAMPAIGNS";
export const DASHBOARD_CHANGE_FILTER = "DASHBOARD_CHANGE_FILTER";
