import { combineReducers } from "redux";

import templateReducer from "./templateReducer";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import dashboardReducer from "./dashboardReducer";
import inventoryReducer from "./inventoryReducer";
import campaignsReducer from "./campaignsReducer";

export default combineReducers({
  template: templateReducer,
  auth: authReducer,
  profile: profileReducer,
  dashboard: dashboardReducer,
  inventories: inventoryReducer,
  campaigns: campaignsReducer,
});
