import {
  INVENTORY_START,
  INVENTORY_FINISH,
  INVENTORY_ERROR,
  SET_INVENTORIES,
  SET_COMPONENT,
  SET_PURCHASED_COMPONENT,
  SET_PREVIEW_ID,
  ADD_INVENTORY,
  REMOVE_INVENTORY,
  SET_PURCHASED_INVENTORIES,
  SET_PURCHASED_PREVIEW,
} from "../types";
import axios from "axios";
import { toast } from "react-toastify";

function start() {
  return { type: INVENTORY_START };
}

function finish() {
  return { type: INVENTORY_FINISH };
}

function setError(err) {
  return { type: INVENTORY_ERROR, payload: err };
}

function setInventories(data) {
  return { type: SET_INVENTORIES, payload: data };
}
function setPurchasedInventories(data) {
  return { type: SET_PURCHASED_INVENTORIES, payload: data };
}

function addToPurchased(data) {
  return { type: ADD_INVENTORY, payload: data };
}
function removeFromPurchased(data) {
  return { type: REMOVE_INVENTORY, payload: data.id };
}
export function setComponent(comp) {
  return { type: SET_COMPONENT, payload: comp };
}
export function setPurchasedComponent(comp) {
  return { type: SET_PURCHASED_COMPONENT, payload: comp };
}
export function previewInventory(inv) {
  return { type: SET_PREVIEW_ID, payload: inv };
}
export function previewPurchasedInventory(inv) {
  return { type: SET_PURCHASED_PREVIEW, payload: inv };
}
export const getInventories = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/inventories/all`,
    })
      .then((result) => {
        console.log(result.data);
        dispatch(setInventories(result.data.message));
        dispatch(finish());
      })
      .catch((err) => {
        dispatch(setError(err));
      });
  };
};
export const getPurchasedInventories = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/myinventories/all`,
    })
      .then((result) => {
        console.log(result.data);
        dispatch(setPurchasedInventories(result.data.message));
        dispatch(finish());
      })
      .catch((err) => {
        dispatch(setError(err));
      });
  };
};

export const addInventory = (inventory) => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/inventories/add/${inventory.tenantId}`,
    })
      .then((result) => {
        console.log(result.data);
        if (result.data.message === "added successfully") {
          toast.success("Added Successfully");
          inventory.added = 1;
          inventory.id = result.data.data.id;
          dispatch(addToPurchased(inventory));
          dispatch(getInventories());
          dispatch(finish());
        } else {
          //If you have more than a response to the backend request, please handle here.
          dispatch(setError("Something went wrong."));
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      });
  };
};
export const deleteInventory = (inventory) => {
  return (dispatch) => {
    dispatch(start());
    console.log(inventory);
    axios({
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/inventories/delete/${inventory.id}`,
    })
      .then((result) => {
        console.log(result.data);
        if (result.data.message === "deleted successfully") {
          toast.success("Deleted Successfully");
          inventory.added = 0;
          dispatch(removeFromPurchased(inventory));
          dispatch(getInventories());
          dispatch(finish());
        } else {
          //If you have more than a response to the backend request, please handle here.
          dispatch(setError("Something went wrong."));
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      });
  };
};
