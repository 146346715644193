import {
  DASHBOARD_START,
  DASHBOARD_ERROR,
  DASHBOARD_FINISH,
  DASHBOARD_SET_CAMPAIGNS,
  DASHBOARD_CHANGE_FILTER,
} from "../types";

const initialState = {
  error: "",
  loading: false,
  campaigns: [],
  filter: { selectedCampaigns: [] },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_START:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DASHBOARD_FINISH:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DASHBOARD_SET_CAMPAIGNS:
      return { ...state, campaigns: action.payload };
    case DASHBOARD_CHANGE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, [action.name]: action.value },
      };
    default:
      return state;
  }
};

export default reducer;
